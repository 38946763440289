import { getYxDetail } from './api'
import { getActivityReal } from "@/views/agentVerificationActivities/hexiao/api";
import { findSalesAreaCombobox } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { cisPublicPath2 } from "@/common/constant.js";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: "",
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/agentVerificationActivities",
          name: "agentVerificationActivities",
          title: "代理商市场活动"
        },
        {
          path: "/agentVerificationActivities/activityView",
          name: "activityView",
          title: "活动查看"
        }
      ],
      pageLoadFlag: false,
      detilInfo:{
      },
      columns: [
        {
          title: "种类",
          dataIndex: "type",
        },
        {
          title: "数量",
          dataIndex: "num",
        },
        {
          title: "单价",
          dataIndex: "price",
        },
        {
          title: "小计",
          dataIndex: "total",
        },
        {
          title: "实际数量",
          dataIndex: "realNum",
        },
        {
          title: "实际单价(元)",
          dataIndex: "realPrice",
        },
        {
          title: "实际小计(元)",
          dataIndex: "realTotal",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
       
       
      ],
      tableData: [],
      MttableData:[],
      lctableData:[],
      zptableData:[],
      fileList:[],
      fileListJS:[],
      yxFileList:[],
      TOtableData:[],
      othertableData:[],
      previewVisible1:false,
      previewVisible:false,
      previewImage:'',
      tableData1:[],
      tableData3:[],
      columns1: [
        {
          title: "物料组",
          dataIndex: "matklName",
          width: 150,
        },
        {
          title: "实际达成（全门店，万元）",
          dataIndex: "storeWideSales",
          width: 150,
        },
        {
          title: "实际达成（全渠道，万元）",
          dataIndex: "OmniChannelSales",
          width: 150,
        },
        {
          title: "预计销售额（万元）",
          dataIndex: "writeOffAmount",
          width: 150,
        },
        {
            title: "计划分摊金额（元）",
            dataIndex: "applyCost",
            width: 150,
        },

      ],
    };
  },

  mounted() {
    if(this.$route.query.id) {
      this.getDetailInfo(this.$route.query.id)
    } else {
      this.$message.warning('获取详情失败')
    }
  },
  
  methods:{
    async handlePreview(file) {
      if (file.url) {
        if(file.url.indexOf('tomcat')!= -1 ){ 
          window.open(cisPublicPath2 +'file-rest/showFile?actionMethod=showFile&pathInfo=' + file.url + '&fileName=' + file.name)
        } else{ 
          window.open(file.url)
        }
      }
    },
    async handlePreview1(file) {
      if(file.url) {
        window.open(file.url)
      }
    },
    getNum(item) {
      let data = {
        custInfoId:  this.detilInfo.custInfoId,// 活动承接代理商
        applyStartTime: this.detilInfo.applyStartTime,// 活动开始时间
        applyEndTime: this.detilInfo.applyEndTime,//活动结束时间
        matklId:item.matklId
      }
      return getActivityReal(data);
    },
    // 获取详情
    async getDetailInfo(aid) {
      let data = {id:aid}
      await getYxDetail(data).then(res=>{
        this.detilInfo = res.data.data
        this.tableData = res.data.data.matklDtoList
        this.MttableData = res.data.data.mediaDtoList
        this.lctableData = res.data.data.tempDtoList
        this.zptableData = res.data.data.giftDtoList
        this.TOtableData = res.data.data.tobDtoList
        this.othertableData = res.data.data.otherDtoList

        this.tableData1 = res.data.data.productLineDtoList
        // 电视强制改成平板电视显示
        this.tableData1.find(j=>j.matklName == '电视').matklName = '平板电视';
        
        if( this.tableData1 .length>0) {
          this.tableData1.forEach(item=>{
            // 参数全有才能调 否则后台报错
            if(this.detilInfo.id && this.detilInfo.applyStartTime && this.detilInfo.applyEndTime && item.matklId){
              let data = {
                id:  this.detilInfo.id,// 活动承接代理商
                applyStartTime: this.detilInfo.applyStartTime,// 活动开始时间
                applyEndTime: this.detilInfo.applyEndTime,//活动结束时间
                matklId:item.matklId
              }
              getActivityReal(data).then( async(res)=>{
                this.$set(item, 'storeWideSales' , res.data.data.activityShopReal);
                this.$set(item, 'OmniChannelSales' , res.data.data.activityChannelReal);
                this.$forceUpdate()
              })
            }
            
          })
        }
        if(res.data.data.attachs) {
          res.data.data.attachs.forEach(item=>{
        
            this.fileList.push(
              {
                uid: item.id,
                name: item.attachName,
                url: item.attachPath,
              },
            );
          })
        }
        if(res.data.data.sumUpAttachs) {
          res.data.data.sumUpAttachs.forEach(item=>{
        
            this.yxFileList.push(
              {
                uid: item.id,
                name: item.attachName,
                url: item.attachPath,
              },
            );
          })
        }
        
        // 结算
        if(res.data.data.voucherAttachs) {
          res.data.data.voucherAttachs.forEach(item=>{
            this.fileListJS.push(
              {
                uid: item.id,
                name: item.attachName,
                url: item.attachPath,
              },
            );
          })
        }

      })
    },
    goback() {
      window.close()
      this.$router.push({ path:'/agentVerificationActivities'});
    }
  }
};