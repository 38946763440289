/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-08 16:45:52
 * @LastEditors: xiaojie 1264801634@qq.com
 * @LastEditTime: 2022-09-15 15:41:58
 * @FilePath: \B2B\src\utils\store.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//数据持久化，将数据存储在sessionStorage里面
// 设置
export const setStoreUser = data => sessionStorage.setItem('sessionuser', JSON.stringify(data))
export const setStoreUser1 = data => sessionStorage.setItem('newtype', JSON.stringify(data))
export const setaddress = data => sessionStorage.setItem('getaddress', JSON.stringify(data))
export const setqystatus = data => sessionStorage.setItem('qystatus', JSON.stringify(data))
export const setzongnian = data => sessionStorage.setItem('zongnian', JSON.stringify(data))
export const setmendian = data => sessionStorage.setItem('mendian', JSON.stringify(data))
export const setimgurl = data => sessionStorage.setItem('imgurl', JSON.stringify(data))
export const setfuzeren = data =>sessionStorage.setItem("fuzeren", JSON.stringify(data));


// 获取
export const getStoreUser = () => (sessionStorage.getItem('sessionuser') ? JSON.parse(sessionStorage.getItem('sessionuser')) : '')
export const getStoreUser1 = () => (sessionStorage.getItem('newtype') ? JSON.parse(sessionStorage.getItem('newtype')) : '')
export const getaddress = () => (sessionStorage.getItem('getaddress') ? JSON.parse(sessionStorage.getItem('getaddress')) : '')
export const getqystatus = () => (sessionStorage.getItem('qystatus') ? JSON.parse(sessionStorage.getItem('qystatus')) : '')
export const getzongnian = () => (sessionStorage.getItem('zongnian') ? JSON.parse(sessionStorage.getItem('zongnian')) : '')
export const getmendian = () => (sessionStorage.getItem('mendian') ? JSON.parse(sessionStorage.getItem('mendian')) : '')
export const getimgurl = () => (sessionStorage.getItem('imgurl') ? JSON.parse(sessionStorage.getItem('imgurl')) : '')
export const getfuzeren = () => (sessionStorage.getItem('fuzeren') ? JSON.parse(sessionStorage.getItem('fuzeren')) : '')

// 清除
export const delStoreUser = () => sessionStorage.removeItem('sessionuser')
export const delStoreUser1 = () => sessionStorage.removeItem('newtype')