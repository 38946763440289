
import http from "@/utils/request";

// 获取列表
export function getofficeList(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getOffice.nd",
        data
    });
}

  
export function getDLlnumSeach(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getAgent.nd",
        data
    });
}

export function getFXlnumSeach(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getDistributor.nd",
        data
    });
}

export function getUsers(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getUsers.nd",
        data
    });
}
// 核销
export function activityApplication(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/writeFlowStart.nd",
        data,
        // contentType: "application/x-www-form-urlencoded",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

// 查看单条数据进行编辑 /custAgentActivity/getActivityById.nd
export function getActivityInfo(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getActivityById.nd",
        data
    });
}

// 获取列表
export function getActivityReal(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getActivityRealByCust.nd",
        data
    });
}

//获取活动属性
export function getActivityAttribute() {
    return http({
        method: "get",
        url: "/custAgentActivity/getActivityAttribute.nd",
    });
}