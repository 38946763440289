
import http from "@/utils/request";
import {getStoreUser1} from '@/utils/store'


// 企业查询 天眼查
export function callTYC(params) {
    return http({
        method: "get",
        url: "/IntentionCust/callTYC.nd",
        params
    });
}



// // 新增编辑保存
// export function delete (data){
//     return http({
//         method: "post",
//         url: "/personnelRecordsManagement/delete.nd",
//         data
//     });
// }

export function showFullIdNumber(data) {
    return http({
        method: "post",
        url: "/personnelRecordsManagement/showIdCard.nd",
        params: data
    });
}
// 获取ciscode
export function getCiseCode(data) {
    return http({
        method: "get",
        url: "/distributor/createCisCode.nd",
        params: data
    });
}

// 查询字典值
export function focusonremarkplz(data) {
    return http({
        method: "get",
        url: "/comm/dict.nd",
        params: data
    });
}
// 渠道类别细分下拉框
export function findSubChannelCategoryCombobox(data) {
    return http({
        method: "get",
        url: "/distributor/findSubChannelCategoryCombobox.nd",
        params: data
    });
}
// 获取集团二级标签
export function groupSecondaryLabel(data) {
    return http({
        method: "get",
        url: "/distributor/findSubGroupLabelCombobox.nd",
        params: data
    });
}

// 渠道三级

// 获取集团二级标签
export function getThirdLabelBySecondLabel(data) {
    return http({
        method: "get",
        url: "/distributor/getThirdLabelBySecondLabel.nd",
        params: data
    });
}
// 城市级别
export function findCityLevelComboboxx(data) {
    return http({
        method: "get",
        url: "/distributor/findCityLevelCombobox.nd",
        params: data
    });
}
// 商家类别
export function findDictionaryCombobox(data) {
    return http({
        method: "get",
        url: "/comm/findDictionaryCombobox.nd",
        params: data
    });
}


// 合作的分销商下拉
export function findBranchCompanyCombobox(data) {
    return http({
        method: "get",
        url: "/distributor/findBranchCompanyCombobox.nd",
        params: data
    });
}



// 省市区

export function findAdministrativeCombobox(data) {
    return http({
        method: "get",
        url: "/comm/findAdministrativeCombobox.nd",
        params: data
    });
}
// 物料组
export function findMaterialGroupCombobox(data) {
    return http({
        method: "get",
        url: "/distributor/findMaterialGroupCombobox.nd",
        params: data
    });
}
//编辑提交数据  
export function flowStart(data) {
     return http({
       method: "post",
       url: "/distributorCreation/flowStart.nd",
       data,
       contentType: "application/json;charset=UTF-8",
       type: "stringfy",
     });
}
//新版--物料公司
export function MaterialCompany() {
    return http({
        method: "get",
        url: "/distributorCreation/findMaterialGroupCombobox.nd",
    })
}


// 合作模式
export function findCooperationModel(data) {
    return http({
        method: "get",
        url: "/distributor/findCooperationModel.nd",
        params: data
    });
}
// 获取销售地址
export function findSalesAreaCombobox(data) {
    return http({
        method: "get",
        url: "/distributor/findSalesAreaCombobox.nd",
        params: data
    });
}

// 银行模糊搜索
export function findBankInfoCombobox(data) {
    return http({
        method: "get",
        url: "/distributor/findBankInfoCombobox.nd",
        params: data
    });
}
//查询银联号
export function findJointBankNumber(data) {
    return http({
        method: "get",
        url: "/distributor/findJointBankNumber.nd",
        params: data
    });
}
// 天眼查数据
export function findCompanyBaseInfo(data) {
    return http({
        method: "get",
        url: "/distributor/findCompanyBaseInfo.nd",
        params: data
    });
}
// 查询所属大区
export function findRegionByProvinceId(data) {
    return http({
        method: "get",
        url: "/distributor/findRegionByProvinceId.nd",
        params: data
    });
}

// // 提交
// export function tmpSave (data){
//     return http({
//         method: "post",
//         url: "/distributor/flowStart.nd",
//         data,
//         contentType: "application/json;charset=UTF-8",
//         type: 'stringfy',
//     });
// }


// 新版提交建户数据
export function tmpSave(data) {
    var type = getStoreUser1
    console.log(type);
    return http({
        method: "post",
        url: "/stepBuildStore/stepStartFlow/1.nd",
        contentType: "application/json;charset=UTF-8",

        data,
       type: 'stringfy'

    }); 
}


// 查询详情
export function detail(data) {
    return http({
        method: "get",
        url: "/distributor/detail.nd",
        params:data
    });
}
// 获取贸易关系详情
export function findTradeRelationDetail (data){
    return http({
        method: "get",
        url: "/distributor/findTradeRelationDetail.nd",
        params:data
    });
}

//销售试图详情
export function findSalesViewDetail (data){
    return http({
        method: "get",
        url: "/distributor/findSalesViewDetail.nd",
        params:data
    });
}
// 获取门店视图
export function findCompanyViewDetail (data){
    return http({
        method: "get",
        url: "/distributor/findCompanyViewDetail.nd",
        params:data
    });
}
// 获取银行详情
export function findBankAccountDetail (data){
    return http({
        method: "get",
        url: "/distributor/findBankAccountDetail.nd",
        params:data
    });
}


//验证身份证之类的
export function stepBuildStoreParamCheck(data) {
    return http({
        url: '/stepBuildStore/stepBuildStoreParamCheck.nd?type=1' ,
        contentType: "application/json;charset=UTF-8",
        type: "stringfy",
        method: 'post',
        data
    })
}
// 测试得到所有的商家数据

// 查询BD
export function getBDList() {
    return http({
        method: "post",
        url: "/comm/getBd.nd",
        params: {}
    });
}

// 根据BD查询BU
export function getBUList(id) {
    return http({
        method: "post",
        url: `/comm/getBu.nd?bd=${id}`,
        params: {}
    });
}
